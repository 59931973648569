<template>
  <v-container>
    <ModalRedirectLogin
      title="Cadastro confirmado com sucesso!"
      text="Você será derirecionado para a tela de LOGIN..."
      :dialog="dialog"
    />
    <v-overlay :value="load">
      <v-progress-circular size="130" color="white" indeterminate>
        Confirmando...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import login from "../../services/login";
import rules from "../../mixins/inputRules";
export default {
  name: "confirm",
  mixins: [rules],
  components: {
    ModalRedirectLogin: () => import("./ModalRedirectLogin")
  },
  data() {
    return {
      dialog: false,
      load: true,
      reenviar: {
        show: false,
        form: {
          email: null
        }
      },
      statusHttp: {
        401: "Tempo para validação do cadastro expirado!",
        400: "Erro ao validadar Cadastro, contate o Administrador!"
      },
      statusHttpType: {
        401: "warning",
        400: "error",
        500: "error"
      }
    };
  },
  methods: {
    async confirm() {
      try {
        this.load = true;
        //eslint-disable-next-line no-unused-vars
        let response = await login.confirmCadastro({
          token: this.$route.params.token
        });
        this.load = false;

        //eslint-disable-next-line no-unused-vars

        this.dialog = true;
      } catch (error) {
        this.$router.push("/cadastro/reenviar-confirmacao");
        if (error.response) {
          this.$notify({
            text:
              "Erro ao confirmar cadastro: " +
              this.statusHttp[error.response.status],
            type: this.statusHttpType[error.response.status]
          });
        }
      } finally {
        this.load = false;
      }
    }
  },

  created() {
    this.confirm();
  }
};
</script>
